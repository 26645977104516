import React from "react";
import "./Market.scss";
import OzonIcon from "../../assets/images/ozon.png";
import { motion } from "motion/react";

const Market = () => {
  const marketLinks = [{ title: "Ozon", href: "#", icon: OzonIcon }];

  const upToAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  return (
    <motion.section
      className="market"
      id="market"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
    >
      <motion.div custom={1} variants={upToAnimation} className="market__title">
        <span>
          Закажи прямо сейчас <span className="primary">на маркетплейсах</span>
        </span>
      </motion.div>

      <motion.div
        custom={2}
        variants={upToAnimation}
        className="market__description"
      >
        <p>Список маркетплейсов, где вы можете приобрести нашу продукцию.</p>
      </motion.div>
      <motion.div custom={3} variants={upToAnimation} className="market__list">
        {marketLinks.map((item) => (
          <div className="market__item" key={item.title}>
            <div className="market__item__left">
              <img src={item.icon} alt={item.title} />
              <h4>{item.title}</h4>
            </div>
            <div className="market__item__right">
              <a href={item.href}>Перейти</a>
            </div>
          </div>
        ))}
      </motion.div>
    </motion.section>
  );
};

export default Market;
