import React, { useEffect } from 'react'
import Hero from '../components/Hero/Hero'
import Advantage from '../components/Advantage/Advantage'
import Products from '../components/Products/Products'
import Market from '../components/Market/Market'
import Faq from '../components/Faq/Faq'
import Gallery from '../components/Gallery/Gallery'

const HomePage = () => {
    useEffect(() => {
      window.scrollTo(0, 0); // Прокрутка к началу страницы
    }, []);
  return (
    <>
        <Hero />
        <Advantage />
        <Market />
        <Faq />
        <Gallery />
    </>
  )
}

export default HomePage