// ---- Настройка сайта ----

import { IMenu } from "../types/types";

export const SiteName = 'TogFor'; // Название
export const SiteEmail = 'support@togfor.ru'; // Почта
export const SiteAdress = 'г. Москва, Московская обл.'; // Адрес
export const SiteAdressCoordinate = '56.9611, 35.1435, 40.2035, 54.2557'; // Координаты адреса
export const SiteShortDescription = 'Лучший Российский бренд одежды'; // Описание сайта

// Элементы меню
export const MenuItems: IMenu[] = [
    {title: 'Главная', route: '/'},
    {title: 'О компании', route: 'about'},
    {title: 'Продукция', route: 'shop'}
    
];