import toast from "react-hot-toast";


export const ErrorFunction = () =>{
    toast.error("Функция временно отключена.");
}

export const ErrorRequest = (req: string) =>{
    toast.error(req + "Произошла ошибка запроса.");
}

export const ErrorResponse = (res: string) =>{
    toast.error(res + "Произошла ошибка запроса.");
}
