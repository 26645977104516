import React, { useEffect } from 'react'
import Products from '../components/Products/Products'
import Market from '../components/Market/Market'
import Faq from '../components/Faq/Faq';

const ShopPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка к началу страницы
  }, []);
  return (
    <>
    <Products />
    <Market />
    <Faq />
    </>
  )
}

export default ShopPage