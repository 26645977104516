import React from "react";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu"; 
import Footer from "./components/Footer/Footer";
import { Toaster } from "react-hot-toast";
import AppRouter from "./components/AppRouter";

function App() {
  return (

      <>
        {/* App  */}
        <Menu />
        <Header />
        <main className="container">

        <AppRouter />

        </main>
        <Footer />

        {/* Added  */}
  
        <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          className: '',
          duration: 1500,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }
        }
        />

      </>
  );
}

export default App;
