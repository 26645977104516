import React, { useEffect, useRef } from "react";
import { createSwapy } from "swapy";
import "./Gallery.scss";
import Logo from "../../assets/images/togfor.png";
import { motion } from "motion/react";
const Gallery = () => {
  const swapy = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    // If container element is loaded
    if (container.current) {
      // @ts-ignore
      swapy.current = createSwapy(container.current);

      // Your event listeners
      // @ts-ignore
      swapy.current.onSwap((event) => {
        console.log("swap", event);
      });
    }

    return () => {
      // Destroy the swapy instance on component destroy
      // @ts-ignore
      swapy.current?.destroy();
    };
  }, []);

  const upToAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  return (
    <motion.section
      className="gallery"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
    >
      <motion.div custom={1} variants={upToAnimation} className="gallery__title">
        <span>
          Галерея <span className="primary">TogFor</span>
        </span>
      </motion.div>

      <motion.div
        custom={2}
        variants={upToAnimation}
        className="gallery__description"
      >
        <p>Вы можете динамично перемещать фотографии</p>
      </motion.div>
      <motion.div  custom={3} variants={upToAnimation} className="gallery__items" ref={container}>
        <div data-swapy-slot="a" className="gallery__items__one">
          <div data-swapy-item="a">
            <img src={Logo} alt="Image 1" />
          </div>
        </div>

        <div data-swapy-slot="b" className="gallery__items__two">
          <div data-swapy-item="b">
            <img src={Logo} alt="Image 2" />
          </div>
        </div>

        <div data-swapy-slot="c" className="gallery__items__three">
          <div data-swapy-item="c">
            <img src={Logo} alt="Image 3" />
          </div>
        </div>

        <div data-swapy-slot="d" className="gallery__items__four">
          <div data-swapy-item="d">
            <img src={Logo} alt="Image 4" />
          </div>
        </div>

        <div data-swapy-slot="e" className="gallery__items__five">
          <div data-swapy-item="e">
            <img src={Logo} alt="Image 5" />
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Gallery;
