import React from "react";
import "./ProductItem.scss";
import { IProductItem } from "../../../types/types";
import OzonIcon from "../../../assets/images/ozon.png";
import TShort from "../../../assets/images/t-shirt.png";
import { ChevronRight } from "lucide-react";

interface Props {
  data: IProductItem;
}

const ProductItem = ({ data }: Props) => {
  return (
    <div className="product__item">
      <div className="top">
        <img
          className="product__item__img"
          src={data.img ? data.img : TShort}
          alt={data.title}
        />
      </div>
      <div className="bottom">
        <div className="product__item__info"> 
          <div className="inline"><ChevronRight width={15}  color="#CAFF33"/><h4>{data.title}</h4></div>
          {/* <div className="inline"><ChevronRight width={15}  color="#CAFF33"/><p>{data.description}</p></div> */}
          <div className="inline"><ChevronRight width={15}  color="#CAFF33"/><span>Коллекция: {"autoTakeCollection"}</span></div>
        </div>
        {/* <div className="product__item__actions">
          <button>Подробнее</button>
        </div> */}
      </div>
      
     
    </div>
  );
};

export default ProductItem;
