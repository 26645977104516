import React from "react";
import "./About.scss";
import { motion } from "motion/react";
const About = () => {
  const upToAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className="about"
    >
      <motion.div custom={1} variants={upToAnimation} className="about__title">
        <span>
          О нашей <span className="primary">компании</span>
        </span>
      </motion.div>
      <motion.div custom={2} variants={upToAnimation} className="about__text">
        <p>
          TogFor (Together Forever) — это российский бренд одежды,
          воплощает в себе дух молодежного и делового стиля. Концепция бренда
          закладывалась в 2020 году, начало реализации в 2024 году,
          стремимся предложить своим клиентам не просто одежду, а уникальный
          опыт, основанный на классических и оригинальных дизайнах, высоком
          качестве и минимализме.
        </p>
        <br />
        <p>
          Каждая коллекция создается с акцентом на доступность, что
          позволяет каждому найти что-то по душе, не жертвуя при этом качеством.
          Мы верим, что стиль может быть как элегантным, так и доступным, и
          именно поэтому наши изделия идеально подходят для повседневной жизни и
          деловых встреч.
        </p>
        <br />
        <p>
          Миссия бренда — объединение людей. Стремимся создать сообщество,
          где каждый сможет выразить свою индивидуальность и найти
          единомышленников. Наша одежда — это не просто вещи, это символы связи
          и единства, которые помогают людям чувствовать себя комфортно и
          уверенно в любой ситуации.
        </p>
        <br />
        <p>
          Вы не просто носите одежду — вы становитесь частью движения,
          которое ценит качество, стиль и человеческие отношения.
          Присоединяйтесь к нам и откройте для себя мир, где мода и единство
          идут рука об руку.
        </p>  
      </motion.div>
    </motion.section>
  );
};

export default About;
