import React, { useEffect } from 'react';
import './SelectCollection.scss';
import { ICollection } from '../../types/types';

interface Props {
    collections: ICollection[]; 
    setSelectedCollection: (el: ICollection) => void;
}

const SelectCollection = (props: Props) => {
    return (
        <select
            className="select__collection"
            onChange={(e) => props.setSelectedCollection(props.collections[parseInt(e.target.value)])}
        >
            {props.collections.map((collection, index) => (
                <option key={collection.name} value={index}>
                    Коллекция: {collection.name}
                </option>
            ))}
        </select>
    );
}

export default SelectCollection;