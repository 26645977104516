import React, { useState } from "react";
import { ICollection } from "../../types/types"; // Adjust the import based on your project structure
import ProductItem from "./items/ProductItem"; // Ensure this path is correct
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "./Products.scss";
import SelectCollection from "../SelectCollection/SelectCollection";

const Products = () => {
  const [collections, setCollections] = useState<ICollection[]>([
    {
      name: "Classic",
      products: [
        {
          id: 1,
          title: "TogFor Classic White",
          description: "Описание 1",
          img: "",
        },
        {
          id: 2,
          title: "TogFor Classic Black",
          description: "Описание 2",
          img: "",
        },
        {
          id: 3,
          title: "TogFor Classic Space",
          description: "Описание 3",
          img: "",
        },
        {
          id: 4,
          title: "TogFor Classic Gray",
          description: "Описание 4",
          img: "",
        },
        {
          id: 456,
          title: "TogFor Classic Gray",
          description: "Описание 5",
          img: "",
        },
        {
          id: 412356,
          title: "TogFor Classic Gray",
          description: "Описание 6",
          img: "",
        },
      ],
    },
    {
      name: "Modern",
      products: [
        {
          id: 5,
          title: "TogFor Modern White",
          description: "Описание 5",
          img: "",
        },
        {
          id: 6,
          title: "TogFor Modern Black",
          description: "Описание 6",
          img: "",
        },
      ],
    },
  ]);

  const [selectedCollection, setSelectedCollection] = useState<ICollection>(collections[0]);

  const upToAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  return (
    <motion.section
      className="products"
      id="products"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
    >
      <motion.div
        custom={1}
        variants={upToAnimation}
        className="products__title"
      >
        <span>
          Наша <span className="primary">продукция</span>
        </span>
      </motion.div>

      <motion.div
        custom={2}
        variants={upToAnimation}
        className="products__description"
      >
        <p>Список нашей продукции</p>
      </motion.div>

      <motion.div
        custom={3}
        variants={upToAnimation}
        className="products__items"
      >
        {collections.length === 0 ? (
          <div className="no-products">
            <h2
              style={{ color: "white", textAlign: "center", marginTop: "50%" }}
            >
              Содержимое не найдено, либо пока не доступно!
            </h2>
          </div>
        ) : (
          <>
          <div className="filter__actions">
            <SelectCollection
                collections={collections}
                setSelectedCollection={setSelectedCollection}
              />
              <input className="search" type="text" placeholder="Введите для поиска..."/>
          </div>

            {selectedCollection.products.length === 0 ? (
              <div className="no-products">
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "50%",
                  }}
                >
                  Содержимое не найдено, либо скоро будет доступно!
                </h2>
              </div>
            ) : (
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                pagination={{ clickable: true }}
                breakpoints={{
                  370: {
                    slidesPerView: 1.2,
                  },
                  400: {
                    slidesPerView: 1.3,
                  },
                  600: {
                    slidesPerView: 2,
                  },
                  850: {
                    slidesPerView: 3,
                  },
                  1140: {
                    slidesPerView: 4,
                  },
                }}
              >
                {selectedCollection.products.map((product) => (
                  <SwiperSlide key={product.id}>
                    <ProductItem data={product} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </>
        )}
      </motion.div>
    </motion.section>
  );
};

export default Products;
