import React, { useEffect } from 'react'
import About from '../components/About/About'

const AboutPage = () => {
  
    useEffect(() => {
      window.scrollTo(0, 0); // Прокрутка к началу страницы
    }, []);
  return (
    <>
        <About />
    </>
  )
}

export default AboutPage