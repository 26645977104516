import React from 'react';
import './Faq.scss';
import { motion } from "motion/react"
const Faq = () => {
  const upToAnimation = {
    hidden:{
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: {delay: custom * 0.2}
    })
  }
  return (
    <motion.section initial="hidden" whileInView="visible" viewport={{amount: 0.2, once: true}} className='faq'>
        <motion.div custom={1} variants={upToAnimation} className="faq__title">
        <span>
          Часто задаваемые <span className="primary">вопросы</span>
        </span>
      </motion.div>

      <motion.div custom={2} variants={upToAnimation} className="faq__details">
        <details open={true}>
            <summary>Где можно купить товар?</summary>
            <p>
              В настоящий момент всю продукцию можно приобрести на маркетплейсах в официальном магазине.
              Функция покупки товаров через официальный сайт временно отключена, но в скором времени будет доступна.
            </p>
        </details>

        <details>
            <summary>Как можно с нами связаться?</summary>
            <p>
             Появился какой-то вопрос, предложение или возникла ошибка - 
             связаться с нами можно по почтовому адресу - support@togfor.ru.
            </p>
        </details>

      </motion.div>
      

    </motion.section>
  )
}

export default Faq