import React from "react";
import "./Advantage.scss";
import { motion } from "motion/react"
import { Factory, TrendingUp, Truck } from "lucide-react";

const Advantage = () => {
  const advantages = [
    {
      title: "Доставка по всей России",
      description:
        "Доставим продукцию в любую точку страны. Нашу продукцию можно так же приобрести при помощи сервисов Ozon или Wildberries.",
      icon: <Truck color="#191919" />,
    },
    {
      title: "Производство в России",
      description: "Производим продукцию в России. Не боимся санкций.",
      icon: <Factory color="#191919" />,
    },
    {
      title: "Качество продукции",
      description:
        "Продукция только из лучших материалов. При изготовлении подбираем нужные пропорции.",
      icon: <TrendingUp color="#191919" />,
    },
  ];

  const upToAnimation = {
    hidden:{
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: {delay: custom * 0.2}
    })
  }

  return (
    <motion.section initial="hidden" whileInView="visible" viewport={{amount: 0.2, once: true}} className="advantage" id="advantage">
      <motion.div custom={1} variants={upToAnimation} className="advantage__title">
        <span>
          Наши <span className="primary">преимущества</span>
        </span>
      </motion.div>

      <motion.div custom={2} variants={upToAnimation} className="advantage__description">
        <p>
          Мы активно развиваемся на рынке и стараемся стать лучшими из лучших.
        </p>
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{amount: 0.2, once: true}} className="advantage__items">
        {advantages.map((item) => (
          <motion.div custom={4} variants={upToAnimation} className="advantage__item" key={item.title}>
            <motion.div custom={5} variants={upToAnimation} className="advantage__item__icon">{item.icon}</motion.div>
            <motion.div custom={6} variants={upToAnimation} className="advantage__item__title">
              <p>{item.title}</p>
            </motion.div>
            <motion.div custom={7} variants={upToAnimation} className="advantage__item__description">
              <p>{item.description}</p>
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </motion.section>
  );
};

export default Advantage;
